@import 'themes.scss';

@import "bootstrap/scss/bootstrap";

body {
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
  text-align: left;
}
ul {
  list-style-type: none;
  padding-left: 0px;
  //list-style-type: inherit;
  text-align: inherit;
  margin: 5px;

  &.numbered {
    list-style-type: decimal;
  }

  &.bold-dots {
    list-style-type: disc;
    font-weight: bold;
  }

  &.pattern {
    list-style-type: square;
  }

  &.hidden {
    list-style-type: none;
    padding-left: 0px;
  }

  li {
    padding: 3px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 0.9em;

    .content {
      display: flex;
      align-items: center;
      justify-content: left;
      position: relative;
    }
  }
}
.navbar-brand {
  text-transform: uppercase;
  img {
    height: 35px;
  }
}
/* Vertical toolbar for desktop */
.toolbar-vertical {
  position: fixed; /* Keeps it in a fixed position */
  top: 60px; /* Adjust according to your header height */
  left: 0; /* Or right: 0 if you want it on the right */
  width: 250px;
  height: calc(100vh - 60px); /* Adjust for viewport height minus header */
  background-color: #f8f9fa;
  z-index: 1040; /* Ensure it's behind the header */
}

/* Add padding to the main content to push it below the header */
main {
  padding-left: 260px; /* Adjust for the width of the toolbar */
  padding-top: 60px; /* Adjust for the height of the header */
}

.conf-header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.toobar-header{
  display: flex;
  flex-direction: row;
}
.toobar-header-mobile{
  display: none;
}

/* Hover effect */
.toolbar-col {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: var(--primary-bg);
  padding: 5px;
  min-height: 7em;
}

.toolbar-col:hover {
  transform: translateY(-3px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-hover);
}


.resume-container {
  padding: 2px;
}


@media screen and (max-width: 800px) {
  .resume-template-page {
    //transform: scale(var(--resume-scale));
    //transform: scale(0.99);
    //transform-origin: top right;
    margin-left: var(--resume-scale-margin-left);
  }
  .conf-header {
    justify-content: left;
  }
  .toobar-header{
    display: none;
  }
  .toobar-header-mobile{
    display: flex;
  }
  .offcanvas.offcanvas-start {
    max-width: 100vw; /* Ensure it doesn't overflow on small screens */
  }
  .btn {
    width: 100%;
    padding: 5px;
  }
}
.resume-template {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.left-column {
  width: calc(100% / (1.618 + 1)); /* Golden ratio for column width */
  box-sizing: border-box;
  border-right: 1px solid var(--resume-secondary-color);
  --var-color: var(--resume-secondary-color);
  --var-color-bg: var(--resume-primary-color);
  color: var(--var-color);
  background-color: var(--var-color-bg);
  padding: 0.5in 0.1in 0.5in 0.3in;
  border-radius: 10px 0 0 10px;
}
.resume-footer{
  position: absolute;
  align-self: end;
  width: 100%;
  padding: 0.1in 0.3in 0.3in 0.3in;
  display: flex;
  opacity: 0.5;
  .resume-name{
    margin-left: auto;
  }
}
.resume-page-seperator{
  padding: 5px;
  width: 100%;
}


.right-column {
  width: calc(100% - (100% / (1.618 + 1))); /* Golden ratio for column width */
  padding: 0.5in 0.3in 0.5in 0.1in;
  box-sizing: border-box;
  --var-color: var(--resume-primary-color);
  --var-color-bg: var(--resume-secondary-color);
  color: var(--var-color);
  background-color: var(--var-color-bg);
  border-radius: 0 10px 10px 0;
}


@media print {
  body {
    -webkit-print-color-adjust: exact; /* Chrome, Safari */
    print-color-adjust: exact;         /* Firefox */
    margin: 0
  }

  /* Hide everything else on the page */
  body * {
    visibility: hidden; /* Use visibility to hide elements but retain layout */
    position: static;   /* Reset position to prevent layout issues */
  }

  /* Show the specific div for printing */
  #printable-content, #printable-content * {
    visibility: visible;
    user-select: text; /* Allow text selection in the printable content */
  }

  /* Position the printable content at the top of the page */
  #printable-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0; /* Remove any margin for cleaner print output */
    overflow: visible; /* Ensure all content is visible */
    z-index: 9999; /* Bring the content to the front if there are any layered elements */
    .resume-page-seperator {
      display: none;
    }
    .resume-template{
      margin: 0;
    }
    .resume-template-page{
      border-radius: 0;
      margin: 0;
    }
    .left-column{
      border-radius: 0;
    }
    .right-column{
      border-radius: 0;
    }
  }
}

.resume-header{
  content: "Hello"
}

.rzp_payment_form {
  margin: 3px 0px 0 0 ;
}