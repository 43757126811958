// styles/themes.scss

$golden-ratio: 1.618;

$primary: #023C40;
$primary-bg: rgba(2, 60, 64, 0.05);
$primary-hover: rgba(2, 60, 64, 0.2);

:root {
  --resume-primary-color: #363f4e;
  --resume-secondary-color: #f4f7f7;
  --resume-scale: 1;
  --editable-primary-color: #f10f0f;
  --editable-hover-color: #a8556e;
}

// Theme customization (optional)
[data-theme="theme2"] {
  // Define custom theme variables here
}
